@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');
:root {
  @apply bg-primary text-primary;
}

body {
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
}

.container {
  @apply w-full px-2 mx-auto rounded bg-primary;
}

.dark {
  --color-bg-primary: #8dc547;
  --color-bg-primary: #121212;
  --color-bg-secondary: #283141;
  --color-text-primary: #dbdbdb;
  --color-text-secondary: #e2e8f0;
  --color-text-accent: #8dc547;
  --color-bg-input: #4a5361;
  --color-bg-button: #9ecf63;
}

.light {
  --color-bg-primary: #e2ece2;
  --color-bg-secondary: #edf2f7;
  --color-text-primary: #121212;
  --color-text-secondary: #4a5568;
  --color-text-accent: #8dc547;
  --color-bg-input: #edf2f7;
  --color-bg-button: #8dc547;
}
